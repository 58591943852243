import {jwtDecode} from 'jwt-decode'
import type { httpError } from '@/api/http_types'
const website = useWebsiteStore()
const error = useErrorStore()

const checkToken = () => {
    const token = website.token
    if (token) {
        const decoded = jwtDecode(token)
        if(decoded.exp){
            if (new Date().getTime() > decoded.exp * 1000 ) {
                // token 过期
                error.setResult({
                    data: {
                        message: '登录已过期，请重新登录, 3秒后跳转到登录页面',
                        code:  401,
                    },
                } as httpError)
            }else{
                return true            
            }
        }
    }
    return false
}

const isAuthenticated = checkToken

export default defineNuxtRouteMiddleware((to, from) => {
    // skip middleware on server
    if (import.meta.server) return
    // isAuthenticated() is an example method verifying if a user is authenticated
    if(to.path === '/login' || to.path === '/register') {
        return
    }
    if (isAuthenticated() === false) {
      return navigateTo('/login')
    }
  })
